var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex dropdownWrapper",style:({width: _vm.width})},[(_vm.labelText !== '')?_c('div',{staticClass:"dropdownLabel content-center",staticStyle:{"width":"40%"}},[_c('div',{staticClass:"font-medium w-100 d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]),_c('div',{staticClass:"line"})]):_vm._e(),_c('div',{staticClass:"custom-select",style:(_vm.labelText !== '' ? 'width: 60%' : 'width:100%'),attrs:{"tabindex":_vm.tabindex},on:{"blur":function($event){_vm.open = false}}},[_c('div',{staticClass:"selected font-medium",class:{ open: _vm.open, default: _vm.selected === _vm.placeHolder },on:{"click":function($event){_vm.open = !_vm.open}}},[_vm._v(" "+_vm._s(_vm.selected)+" ")]),_c('div',{staticClass:"items",class:{ selectHide: !_vm.open },style:(_vm.options.length <= 6 ? 'height: auto;' : 'height: 300px')},[_c('div',{staticClass:"item d-none"},[_vm._v(_vm._s(_vm.placeHolder))]),_vm._l((_vm.options),function(option,i){return _c('div',{key:i,staticClass:"item",class:{
          'disable-option': option.id === _vm.selectedObj.id,
          disabled: option.disable,
        },on:{"click":function($event){_vm.selected = option.text;
          _vm.selectedObj = option;
          _vm.open = false;
          _vm.$emit('input', option);}}},[_vm._v(" "+_vm._s(option.text)+" ")])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }